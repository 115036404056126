import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VAlert,{attrs:{"type":"info","width":"600","color":"primary","text":"","shaped":""}},[_c('b',[_vm._v("Nyheten havner i \"Siste nytt\" i app.learnlink.no for Pro's.")])]),_c(VCard,{staticClass:"pa-4",attrs:{"height":"400","width":"600"}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_c(VContainer,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Emne","outlined":"","dense":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c(VRow,{staticClass:"mt-0"},[_c(VCol,[_c(VTextarea,{attrs:{"label":"Innhold","outlined":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c(VRow,{staticClass:"mt-0"},[_c(VCol,[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.formValidation},on:{"click":_vm.submit}},[_vm._v(" Send! ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }