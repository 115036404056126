



























































import { createNewsMessage } from "@/api/news-service";
import linkify from "@/helpers/linkifyWithSeparator";
import store from "@/store/store";
import Vue from "vue";

export default Vue.extend({
  name: "CreateNewsMessage",
  data() {
    return {
      loading: false,
      message: "",
      title: "",
    };
  },
  computed: {
    formValidation(): boolean {
      return !this.title || !this.message;
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await createNewsMessage({
          message: linkify(this.message),
          title: this.title,
        });

        await store.dispatch("displayAlert", {
          color: "success",
          message: "Lagret! 🥳",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: e,
        });
      }
      this.message = "";
      this.title = "";
      this.loading = false;
    },
  },
});
